// page
.cr-page {
  min-height: 100vh;
  &__header {
    @include media-breakpoint-down(sm) {
      flex-direction: column;
      align-items: flex-start;
    }
    display: flex;
    align-items: flex-end;
    align-self: center;
  }

  &__title {
    @include media-breakpoint-down(sm) {
      margin-bottom: 0;
    }
    padding-right: 0.5rem;
    margin-bottom: 1rem;
  }

  &__breadcrumb {
    @include media-breakpoint-down(sm) {
      align-self: flex-start;
    }
    align-self: flex-end;
    padding: 0;
    text-transform: capitalize;
    background: theme-color('light');
  }

  #header-breadcrumb .breadcrumb {
    margin-bottom: 0;
    background: none;
    font-size: 1.7rem;
    font-weight: normal;
    color: rgb(92, 92, 92);
    position: absolute;
    top: 5px;
    // left: $cr-sidebar-width + 70px;
    // transition: left 0.2s ease-in-out;

    // &.sidebar-close {
    //   left: $cr-sidebar-closed-width + 70px;
    // }

    .breadcrumb-item {
      a {
        color: rgb(92, 92, 92);
        font-weight: 500;
      }

      &::before {
        color: rgb(92, 92, 92);
      }

      &.active {
        color: rgb(48, 48, 48);
      }
    }
  }

  .row {
    // stylelint-disable-next-line
    .col,
    > [class^='col-'] {
      margin-bottom: 1rem;
    }
  }
}

.cr-page-spinner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
