// .nav-item {
//   .nav-link {
//     display: flex;
//     align-items: center;

//     &.active {
//       color: #fff;
//       background: rgba(255, 255, 255, .23);
//       opacity: 1;
//     }
//   }
// }

$black: rgb(78, 78, 78);

@mixin black-or-white-color-tabs($color) {
  .nav-item {
    .nav-link {
      display: flex;
      align-items: center;
      color: $color;
  
      &.active {
        color: $color;
        // color: #fff;
        background: rgba(255, 255, 255, .23);
        opacity: 1;
      }
    }
  }
}

#sidebar-navs {
  @include black-or-white-color-tabs(#fff);
}

// #analytics-tabs {
//   @include black-or-white-color-tabs(($black));
// }

#tasks-tabs {
  @include black-or-white-color-tabs(($black));
}

.sidebar .nav a {
  transition: all 150ms ease-in;
}

.card-header {
  text-transform: uppercase;
}

.badge:empty {
  display: inline-block;
}





.sidebar .nav a {
  transition: all 150ms ease-in;
}

.card-header {
  text-transform: uppercase;
}

.badge:empty {
  display: inline-block;
}