@import '../../../../../styles/variables.scss';

.all-graphics-grid {
  background-color: #f2f2f2;
  padding-top: 10px;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;

  .section {
    max-height: 90%;
    // max-width: 80%;
    width: 98%;
    // overflow: hidden;

    .section-header {
      font-size: 5rem;
    }

    .section-body {
      padding-top: 30px;
      padding: 20px;
      height: 90%;
      overflow: auto;
      // display: flex;
      // align-items: center;
      // justify-content: center;

      .card-columns {
        overflow: auto;
        column-count: 5;

        .card {
          height: 300px;

          .card-header {
            height: 20%;
            background-color: map-get($theme-colors, "primary" );
            color: white;
            font-size: 1.1rem;
            display: flex;
            align-items: center;
          }

          .card-img-top {
            height: 60%;
            object-fit: contain;
            padding: 5px;
          }

          .card-body {
            padding: 12px;
            border-top: 1px solid rgba(0, 0, 0, 0.125);
            height: 20%;
            font-size: 1.1rem;
            display: flex;
            align-items: center;
          }

          a {
            color: black;
            // width: 10px;

            // .card-img-top {
            //   width: 10%;
            // }

            &:hover {
              text-decoration: none;
            }
          }
          // width: 30vh;

          // .card-img-top {
          //   // height: 30vh;
          //   object-fit: cover;
          //   object-position: top;
          // }
        }
      }
    }
  }
}

.upload-button {
  font-size: 1.3rem !important;
  // background-color: rgb(177, 112, 181) !important;
  // border: 1px solid white; 
  // color: black;
  margin-right: 10px;
  margin-left: auto;

  // &:hover {
  //   background-color: rgb(218, 214, 214);
  //   border: 1px solid rgb(218, 214, 214);
  //   color: black;
  // }
}

.shadow-giving-class {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}
