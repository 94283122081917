.sign-in {
    background-color: rgb(255, 255, 255);
    border-radius: 23px;
    padding: 2em 2em;
    text-align: center;
    width: 30em;

    .title {
        margin: 0 0 1em 0;
        line-height: 1;
        letter-spacing: 2px;
    }
}