.scheduler-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background: white;
    border-radius: 30px;

    .details-section {
        display: flex;
        flex-direction: column;
        margin-right: 2em;

        .title {
            font-size: 75px;
            text-align: center;
        }
        .subtitle {
            font-size: 20px;
            text-align: center;
            margin-bottom: 4em;
        }
        .inventory-animation {
            display: flex;
            align-self: center;
            width: 280px    !important;
            height: 280px   !important;
        }
    }

    .scheduler-form {
        min-width: 60%;
    }
}

@media only screen and (max-width: 600px) {
    .scheduler-container {
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }
    .details-section {
        .title {
            font-size: 40px !important;
            text-align: center;
        }
        .subtitle {
            font-size: 20px !important;
            text-align: center;
            margin-bottom: 4em;
        }
    }
}