// .cells-container {
//     display: flex;
//     align-items: center;

//     .editable-cell {
//         display: inline-block;
//         width: fit-content;
//         padding: 0.7em 2em;
//         background-color: white;
//         border-radius: 5px;
//         border: 1px solid grey;
//         margin: 10px 15px 10px 0;
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         font-weight: 400;
//         cursor: pointer;
//         transition: border-color 0.2s ease-in-out;

//         &.no-edit {
//             cursor: default;

//             &:hover {
//                 border-color: grey;
//             }
//         }

//         &:hover {
//             border-color: rgb(76, 133, 238);
//         }
//     }

//     .input-button-container {
//         position: relative;

//         .editable-input {
//             margin: 10px 15px 10px 0;
//             padding: 0.7em 2em;
//             border-radius: 5px;
//             border: none;
//             border: 1px solid rgb(76, 133, 238);
//             transition: box-shadow 0.2s ease-in-out;

//             &:focus {
//                 box-shadow: 0 0 6px -3px rgb(0, 96, 185);
//                 outline: none
//             }
//         }

//         .editable-buttons-container {

//             .editable-button {
//                 margin-right: 15px;
//                 height: 40px;
//                 width: 40px;
//                 background-color: rgb(1, 136, 1);
//                 border: none;
//                 color: white;
//                 border-radius: 50%;
//                 font-size: 10px;

//                 &.secondary {
//                     background: none;
//                     border: 1px solid red;
//                     color: red;
//                 }
//             }
//         }
//     }
// }

// .publish-button {
//     margin-top: 80px;
//     padding: 1em 3em;
//     border: 2px solid #052762;
//     background-color: white;
//     font-size: 1.3rem;
//     transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;

//     &:hover {
//         background-color: #052762;
//         color: white;
//     }
// }

.editable-cell {
    position: relative;
}

.editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
    padding: 4px 11px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
}
