.cr-sidebar {
  // @include media-breakpoint-down(md) {
  //   display: none;
  // }

  position: fixed;
  top: 0;
  bottom: 0;
  // left: 0 - $cr-sidebar-width + $cr-sidebar-closed-width;
  z-index: 5;
  width: $cr-sidebar-closed-width;
  font-weight: 200;
  color: #fff;
  background-position: center center;
  background-size: cover;
  transition: width 0.1s ease-in;
  // font-size: 1.2rem;

  #brand-img {
    background: url(../../assets/img/sidebar/fs-logo-without-name.png);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    height: 100%;
    width: 70%;
    margin: 0 auto;
    border: none;
    opacity: 0.8;
  }

  + .cr-content {
    transition: margin 0.1s ease-in;
    margin: 0 0 0 $cr-sidebar-closed-width;
  }

  &::after,
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    display: block;
    width: 100%;
    height: 100%;
    content: "";
  }

  &::before {
    background: #000;
    opacity: 0.33;
  }

  &::after {
    z-index: 3;
    // sublime light
    background: #0b5394; /* fallback for old browsers */
    // background: linear-gradient(to bottom, #052762, #0B5394);
    background: linear-gradient(to bottom, theme-color("primary"), theme-color("secondary"));
    opacity: 1;
  }

  &[data-image]:after {
    opacity: 0.77;
  }

  &__content {
    position: relative;
    z-index: 4;
    height: calc(100vh);
    // padding-bottom: 30px;
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    display: block;
    width: 100%;
    height: 100%;
    background-position: center center;
    background-size: cover;
  }

  &__nav-item-collapse {
    display: flex;
    justify-content: flex-start;
    cursor: pointer;
  }

  &__nav-item-icon {
    // @include media-breakpoint-down(md) {
    //   padding: 0;
    //   margin: 0 auto;
    // }
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 1.5rem;
  }

  &__nav-item-icon-dropdown {
    // @include media-breakpoint-down(md) {
    //   padding: 0;
    //   margin: 0 auto;
    // }
    width: 1.3rem;
    height: 1.3rem;
    // margin-right: 1.5rem;
  }

  .nav-item-name {
    display: none;
  }

  &--open {
    left: 0;
    transition: left 0.2s ease-in;
    width: $cr-sidebar-width;
    transition: width 0.1s ease-in;

    .nav-item-name {
      display: inline-block;
    }

    .cr-sidebar__nav-item-collapse {
      justify-content: space-between;
    }

    #brand-img {
      background: url(../../assets/img/sidebar/FS_Logo_White.png);
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      height: 100%;
      width: 70%;
      margin: 0 auto;
      border: none;
      opacity: 1;
    }

    + .cr-content {
      margin: 0 0 0 $cr-sidebar-width;
      transition: margin 0.2s ease-in;
    }
  }

  .nav {
    margin-top: 20px;

    .client-select {
      margin: 5px 10px;
      padding: 10px;
      cursor: pointer;
      padding-bottom: 15px;
      border-bottom: 1px solid #ffffff20;
      
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 15px;
      position: relative;
      left: -10px;

      &:hover {
        background: rgba(theme-color(light), 0.15);
      }
    }

    .sidebar-dropdown {
      &.open {
        background-color: rgba(255, 255, 255, 0.08);

        // .collapse,
        // .collapsing {
        //   .nav-link {
        //     padding-left: 15px;
        //   }
        // }
      }

      .select-text { 
        color: theme-color(light);
        font-weight: 400;
        font-size: 1.2em;
      }

      .arrow {
        display: inline-block;
        width: 50px;
        margin-left: auto;
      }

      .collapse,
      .collapsing {
        .nav-link {
          padding-left: 30px;
        }
      }
    }

    .client-option {
      margin: 0px 30px;
      padding: 10px 10px;
      color: theme-color(light);
      font-weight: 500;
      text-transform: uppercase;
      opacity: 0.86;  
      
    }

    .cl-active {
      background: rgba(102, 189, 105, 0.4);
    }

    .cl-other {
      cursor: pointer;
      
      &:hover {
        background: rgba(theme-color(light), 0.15);
      }
    }

    .nav-item {
      // padding: 5px 10px;
      color: theme-color(light);
      border-radius: 4px;
      opacity: 0.86;
      position: relative;
    }

    .nav-link {
      // @include media-breakpoint-down(md) {
      //   padding: .5rem;
      // }
      padding: 15px;
      color: theme-color(light);

      &.active {
        &::before {
          content: "";
          width: 3px;
          background-color: white;
          height: 51px;
          display: block;
          position: absolute;
          left: 0;
        }
      }

      &:hover {
        background: rgba(theme-color(light), 0.15);
      }

      .side-badges {
        font-size: 75%;
        color: #fff;

        .notif-badge {
          border-radius: 0.25rem;
          background-color: #e04040;
          padding: 0.25em 0.45em;
          margin: 0px 0px 0px 5px;
        }

        .task-badge {
          border-radius: 0.25rem;
          background-color: #f0ad4e;
          padding: 0.25em 0.45em;
          margin: 0px 0px 0px 25px;
         }
      }
    }
  }
}

// @include gradient-y(#02aab0, #00cdac); // green beach
// @include gradient-y(#348f50, #56b4d3); // emerald water
// @include gradient-y(#4CB8C4, #3CD3AD); // sea weed

// stripe
// background: #1fa2ff; /* fallback for old browsers */
// background: linear-gradient(
//   to bottom,
//   #1fa2ff,
//   #12d8fa,
//   #a6ffcb
// );

// subu
// background: #0cebeb; /* fallback for old browsers */
// background: -webkit-linear-gradient(
//   to bottom,
//   #29ffc6,
//   #20e3b2,
//   #0cebeb
// ); /* Chrome 10-25, Safari 5.1-6 */
// background: linear-gradient(
//   to bottom,
//   #29ffc6,
//   #20e3b2,
//   #0cebeb
// ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

// mojito
// background: #1d976c; /* fallback for old browsers */
// background: -webkit-linear-gradient(
//   to bottom,
//   #93f9b9,
//   #1d976c
// ); /* Chrome 10-25, Safari 5.1-6 */
// background: linear-gradient(
//   to bottom,
//   #93f9b9,
//   #1d976c
// ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
