@import '../../styles/_variables.scss';

.error-component-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .error-component {
        min-height: 500px;
        min-width: 800px;
        height: 500px;
        width: 100px;
        background-color: white;
        position: relative;
        top: -100px;
        border-radius: 10px;
        box-shadow: 0 0 6px 3px rgba(202, 202, 202, 0.25);
        padding: 32px 10px 10px 40px;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

        .error-component-heading {
            font-size: 72px;
            font-weight: 600;
            color: #202020;
            margin: 0;
        }

        .error-component-text {
            font-size: 18px;
            color: #676767;
            margin-top: 40px;
        }

        .error-exclamation {
            position: absolute;
            bottom: 136px;
            left: 0;
            width: 100%;
        }

        .error-component-footer {
            width: 100%;
            height: 136px;
            position: absolute;
            bottom: 0;
            left: 0;
            display: flex;
            align-items: center;
            padding: 27px;
            justify-content: flex-end;
            gap: 23px;

            .error-component-button {
                font-size: 16px;
                color: #323232;
                padding: 16px 27px;
                border: 2px solid map-get($map: $theme-colors, $key: "primary");
                background-color: white;
                transition: box-shadow 0.2s ease-in-out;

                &.primary {
                    background-color: map-get($map: $theme-colors, $key: "primary");
                    color:  white;
                }

                &:hover {
                    box-shadow: 0 0 6px 3px #05276220;
                }
            }
        }

    }
}