.headings {
    font-weight: bold;
    border-bottom: 1px solid #dddcdc;
    padding-left: 15px;
}

.row-data {
    padding-top: 10px;
    padding-left: 20px;
    cursor: pointer;
}

.docs {
    padding: 20px;
    display: block;
    width: 100%;
}