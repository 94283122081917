@import '../../styles/_variables.scss';

.claim-modal {

    .claims-modal-body {
        display: flex;
        max-height: 450px;
        padding: 20px 25px;

        .img-button {
            width: 40%;

            .user-img {
                width: 100%;
                border-radius: 5px;
                // height: ;
            }

            .start-chat {
                width: 100%;
                padding: 0.5em 1.5em;
                border: 2px solid map-get($map: $theme-colors, $key: "primary");
                color: map-get($map: $theme-colors, $key: "primary");
                background-color: white;
                margin-top: 18px;
                border-radius: 5px;
                font-size: 20px;
                font-weight: 600;
                text-align: center;
                transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;
                
                cursor: pointer;
                &:hover {
                    background-color: map-get($map: $theme-colors, $key: "primary");
                    color: white;
                }
            }
        }

        .user-details {
            width: 60%;
            padding: 0 25px;
            overflow-y: auto;

            .basic-user-details {
                .user-name {
                    font-size: 2.5rem;
                    line-height: 2.5rem;
                }

                .detail-component {
                    display: flex;
                    margin: 8px 0;
                    color: rgb(126, 126, 126);
                    
                    .detail-icon {
                        width: 1.4rem;
                    }
                    
                    .detail-text {
                        font-size: 16px;
                        margin-left: 10px;
                    }
                }
            }

            .divider {
                width: 100%;
                height: 1px;
                background-color: #d8d8d8;
                margin: 20px 0;
            }

            .chatbot-details {
                h4 {
                    font-size: 1.8rem;
                    margin-bottom: 20px;
                }

                .chatbot-keys {
                    padding: 0.5em 1em;
                    background-color: map-get($map: $theme-colors, $key: "primary");
                    border-radius: 150px;
                    color: white;
                    margin-bottom: 12px;
                    font-size: 1.15rem;
                    width: 100%;
                    height: 40px;
                    cursor: pointer;
                    transition: background-color 0.1s ease-in-out;

                    // Not wrapping the text
                    white-space: nowrap;
                    // Not making the text overflow
                    overflow: hidden;
                    // Overflown text to be shown as ellipsis
                    text-overflow: ellipsis;

                    .detailKey {
                        font-weight: 600;
                    }

                    .value {
                        font-weight: 200;
                    }

                    &:hover {
                        background-color: #031e4d;
                    }
                }
            }
        }
    }
    
    .claims-modal-footer {
        height: 50px;
    }
}