@import "../variables";

.integrations-page {
    padding-top: 50px;

    .integration-card {
        width: 310px;
        margin-right: 50px;
        float: left;
        height: fit-content;
        padding: 10px 15px;

        .icon {
            height: 70px;
            width: 100%;
            display: flex;
            justify-content: center;
            margin-bottom: 25px;

            img {
                height: 100%;
            }
        }

        .content {
            margin-bottom: 30px;
        }

        // .buttons-container {
        //     display: flex;

        .connect-button {
            width: 100%;
        }

        

        // }

        // .connected-button-container {
        //     display: flex;
        //     justify-content: space-between;

        //     button {
        //         width: 48%;
        //     }
        // }
    }
}

.validate-btn-container {
    text-align: right;

    #validate-btn {
        margin-bottom: 3px;
    }
}

#spinner {
    font-size: 0.5rem;
    height: 19px;
    width: 19px;
}

.popover-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .icon {
        font-size: 1.5rem;
        cursor: pointer;
    }
}
