.sliders-container {
  // margin-top: 30px;
  width: 49vw;
  margin: 0 auto;
  // margin-left: 20%;
  height: 90%;
  position: relative;
  transition: all ease-in-out 0.3s;
  left: 0;

  &.comments-visible {
    left: -10%;
  }

  .buttons {
    position: absolute;
    top: 50%;
    right: -60px;
    display: flex;
    flex-direction: column;
    transition: all ease-in-out 0.3s;
    transform: translate(0, -50%);
    
    .button {
      margin-bottom: 10px;
      transition: all ease-in-out 0.3s;
      height: 40px;
      // position: relative;
      // left: 0;
      
      &.comments-visible {
        height: 60px;
      }
    }
  }

  .section {
    padding: 0 0 20px 0;
    
    .section-heading {
      .info-button {
        font-size: 1.5rem;
        padding: 0.5rem;
        display: flex;
        margin-left: auto;
        margin-right: 10px;
        cursor: pointer;
      }
    }

    .section-body {
      // margin-top: 20px;
      width: 100%;

      .main-slider {
        width: 100%;
        margin: auto;

        .card {
          border: white 1px solid;
          border-radius: 0px;
          background-color: white;

          .react-card-flip {
            width: 100%;

            .image-container {
              background-color: white;
              display: flex;
              justify-content: center;
              align-items: center;
              // height: fit-content;
              height: 77vh;
              padding-top: 20px;              

              .card-img {
                width: 80%;
                max-height: 77vh;
                margin: auto auto;
                object-fit: contain;
                object-position: top;
                border: white 1px solid;
                border-radius: 0px;
                cursor: pointer;
              }
            }

            .details {
              width: 100%;
              height: 77vh;
              display: flex;
              justify-content: center;
              align-items: center;

              & > div {
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: rgba(172, 169, 169, 0.336);
                border-radius: 25px;
                flex-direction: column;
                width: 50%;
                height: 100%;

                div {
                  width: fit-content;
                }
              }
            }
          }
        }
      }

    }
  }

  .versions {
    position: fixed;
    left: 50%;
    bottom: -35vh;
    transform: translate(-50%, -10px);
    // margin: 0 auto;
    background-color: white;
    height: 30vh;
    width: 80%;
    box-shadow: 0px 0px 3px -1px #676262;
    transition: all ease-in-out 0.3s;
    z-index: 3;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &.show {
      bottom: 0px;
    }

    .close-icon {
      position: absolute;
      right: 15px;
      top: 15px;
      cursor: pointer;
      z-index: 3;
    }

    .nav-slider {
      // height: 10%;
      // width: 80%;
      // margin: auto;
      // height: 80%;

      .card {
        border: rgb(255, 255, 255) 1px solid;
        border-radius: 0px;
        margin: 0 10px;
        // height: 100%;
        height: 23vh;

        .card-img {
          height: 23vh;
          object-fit: cover;
          object-position: top;
          background-color: white;
          border-radius: 0px;
        }
      }

      // .slick-arrow {
      //   &.slick-next {
      //     right: -42px;

      //     &::before {
      //       color: black;
      //     }
      //   }

      //   &.slick-prev {
      //     left: -42px;

      //     &::before {
      //       color: black;
      //     }
      //   }
      // }

      // .slick-dots {
      //   bottom: -12px;
      // }
    }
  }

  .show-versions {
    height: fit-content;
    width: fit-content;
    padding: 15px;
    background-color: white;
    position: absolute;
    bottom: -20px;
    left: 50%;
    transform: translate(-50%, 0);
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    box-shadow: 0px 0px 5px -1px #676262;
    border-radius: 5px;
    cursor: pointer;
  }
}

a {
  border: none;
}

.displayIt {
  display: block;
}

.approval-icon {
  height: 50px;
  width: 75px;
  position: absolute;
  top: 50px;
  right: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }

  .approval-text {
    font-size: 0.9rem;
  }
}
