.cr-header {
  margin-bottom: $spacer * 0.5;
  border-bottom: $border-width solid $border-color;

  &__nav-right {
    flex-direction: row;
    justify-content: flex-end;
    margin-left: auto;
    align-items: center;
  }

  button {
    border-color: #afafaf;
    color: map-get($map: $theme-colors, $key: "primary");

    &:hover {
      background-color: map-get($map: $theme-colors, $key: "primary");
      color: white;
    }

    svg {
      stroke-width: 6;
      stroke-linejoin: miter;
      stroke-linecap: square;
      transition: transform 0.3s ease-in-out;

      &.sidebar-close {
        transform: rotateZ(180deg);
      }
    }
  }

  .notification-icon {
    // border: 1px solid rgb(226, 226, 226);
    padding: 5px;
    // border-radius: 50%;
    padding-right: 20px;
    display: flex;
    color: map-get($map: $theme-colors, $key: "primary");
    position: relative;
    transition: box-shadow ease-in-out 0.2s;
    border-right: 1px solid rgb(214, 214, 214);

    // &:hover {
    //   box-shadow: 0px 0px 8px -6px black;
    // }
  }

  .task-badge {
    width: 8px;
    height: 8px;
    font-size: 10px;
    border-radius: 50%;
    background-color: map-get($map: $theme-colors, $key: "primary");
    position: absolute;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    // font-weight: bolder;
    top: 5px;
    right: 22px;
    border: 2px solid white;
    box-sizing: content-box;
  }

  .header-username {
    color: grey;
    // font-size: 1.2rem;
    margin: 0 10px 0 20px;
  }

  // &__notification-popover {
  //   &.popover {
  //     width: 320px;
  //     max-width: 320px;
  //     max-height: 100vh;
  //     overflow-y: scroll;
  //     cursor: pointer;
  //   }
  // }
}

#user-card {
  .list-group-item {
    a {
      display: flex;
      align-items: center;

      .card-button-text {
        color: black;
        display: inline-block;
        margin-left: 10px;
        position: relative;
        top: 1px;
        // font-weight: 300;

        &:hover { 
          text-decoration: none;
        }
      }
    }

    .card-button-text {
      color: black;
      display: inline-block;
      margin-left: 10px;
      position: relative;
      top: 1px;
      // font-weight: 300;
    }
  }
}
