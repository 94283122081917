// @import "~slick-carousel/slick/slick.css";
// @import "~slick-carousel/slick/slick-theme.css";
@import url("https://fonts.googleapis.com/css2?family=Exo:wght@400&family=Montserrat:wght@100;400&family=Open+Sans:ital,wght@0,300;1,400&display=swap");

#campaign-creator-container {
    @import "../variables";
    @import "~bootstrap/scss/bootstrap";

    height: auto;
    width: 50vw;
    margin: 60px auto auto auto;
    background-color: white;
    // border-radius: 5px;
    box-shadow: 0 3px 13px 1px hsla(0, 0%, 58%, 0.32);
    overflow: visible;
    min-height: 600px;
    // float: left;
    // transition: all ease-in-out 0.3s;

    a {
        color: inherit;
        transition: all ease-in-out 0.3s;
    }

    .outer-slider {
        position: relative;
        // overflow: visible;

        .inner-slider {
            // padding-top: 10vh;
            position: relative;
            height: 65vh;
            min-height: 550px;
            // display: flex;
            // justify-content: center;
            // align-items: center;
            flex-direction: column;

            .date-picker {
                height: 65px;
                // height: 100%;
                width: 30%;
                padding: 10px;
                border: 1px solid map-get($map: $theme-colors, $key: "primary");
                position: relative;
                cursor: pointer;
                border-radius: 5px;
                margin: 0px 18px;

                transition: all ease-in-out 0.3s;
                // transition: width ease-in-out 0.3s, height ease-in-out 0.3s 0.3s;

                &:hover {
                    color: white;
                    background-color: map-get($map: $theme-colors, $key: "primary");

                    .ant-picker-input {
                        input {
                            color: white;
                            &::placeholder {
                                color: white;
                            }
                        }
                    }
                }

                .ant-picker-input {
                    input {
                        cursor: pointer;
                        color: black;
                        text-align: center;

                        &::placeholder {
                            color: black;
                            text-align: center;
                        }
                    }
                }

                .ant-picker-clear {
                    background-color: map-get($map: $theme-colors, $key: "primary");
                    color: white;
                    position: relative;
                    top: 7px;
                }
                .ant-picker-suffix {
                    display: none;
                }
            }

            .slider-view {
                width: 50vw;
                height: 50vh;
                // display: flex !important;
                flex-direction: column;
                // align-items: center;
                // justify-content: center;
                padding: 50px;
                // height: 25vh;
                overflow-y: auto;
                // overflow-x: hidden;

                .question-container {
                    height: fit-content;
                    width: 100%;
                    margin-bottom: 30px;
                    position: relative;

                    .question-heading {
                        font-family: "Open Sans", sans-serif;
                    }

                    .question-description {
                        font-family: "Exo", sans-serif;
                        margin-bottom: 30px;
                        color: #8a8787c7;
                    }

                    .question-input {
                        width: 60%;
                        border: 1px solid #79797961;
                        padding: 5px;
                        position: relative;
                        transform-origin: left;

                        transition: transform cubic-bezier(0.36, -0.45, 0.63, 1.38) 0.4s;

                        &::placeholder {
                            font-size: 0.8rem;
                            font-weight: 300;
                        }

                        &:focus {
                            transform: scale(1.1);
                            border: 1px solid #79797961;
                        }

                        &:disabled {
                            background-color: white;
                        }
                    }

                    .error-text {
                        color: red;
                        font-size: 0.8rem;
                        opacity: 0;
                        position: relative;
                        top: 10px;
                        margin-top: 5px;

                        transition: all ease-in-out 0.2s;

                        &.show {
                            opacity: 1;
                            top: 0;
                        }
                    }

                    .options-graphics-container {
                        /* .row {
                                            display: flex;
                                            align-items: center;
                                        } */

                        .graphics {
                            width: 100%;
                            padding-top: 40%;
                            // display: grid;
                            transition: background-image ease-in-out 0.3s;

                            .option-graphic {
                                width: 50%;

                                // transition: all ease-in-out 0.3s;
                            }

                            .graphic-description {
                                font-family: "Exo", sans-serif;
                                text-align: center;
                                margin-bottom: 0;
                                color: #8a8787c7;
                                grid-column-start: 1;
                                grid-column-end: 4;
                            }
                        }

                        .selected-graphics {
                            display: grid;
                            grid-template-rows: auto auto auto;
                            grid-template-columns: auto auto auto;
                            row-gap: 10px;
                            column-gap: 10px;
                            height: 100%;

                            .de-selected {
                                position: relative;
                                bottom: -10px;
                                opacity: 0.2;
                                transition: all ease-in-out 0.2s;
                            }

                            .selected {
                                transition: all ease-in-out 0.2s;
                                position: relative;
                                bottom: 0;
                                opacity: 1;
                            }

                            .card {
                                height: 100%;
                                border: none;
                            }
                        }

                        .graphics-selected {
                            width: 100%;
                            padding-top: 40%;
                            // display: grid;
                            transition: background-image ease-in-out 0.3s;

                            .graphic-description-selected {
                                font-family: "Exo", sans-serif;
                                text-align: center;
                                margin-bottom: 0;
                                color: #8a8787c7;
                                grid-column-start: 1;
                                grid-column-end: 4;
                            }
                        }

                        .options {
                            .option-button {
                                width: 47%;
                                height: 100%;
                                float: left;
                                margin: 0 7px;
                                font-weight: 300;
                                position: relative;

                                .beta-badge {
                                    position: absolute;
                                    top: 5px;
                                    right: -10px;
                                }

                                label {
                                    height: 65px;
                                    // height: 100%;
                                    width: 100%;
                                    padding: 10px;
                                    border: 1px solid map-get($map: $theme-colors, $key: "primary");
                                    position: relative;
                                    cursor: pointer;
                                    border-radius: 5px;
                                    text-align: center;
                                    overflow: hidden;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;

                                    transition: all ease-in-out 0.3s;
                                    // transition: width ease-in-out 0.3s, height ease-in-out 0.3s 0.3s;

                                    &:hover {
                                        background-color: map-get($map: $theme-colors, $key: "primary");
                                        color: white;
                                    }
                                }

                                &.small-label {
                                    width: 30%;
                                    // font-weight: 400;

                                    label {
                                        height: 45px;
                                    }
                                }

                                input:disabled + label {
                                    // background-color: #f2f2f2;
                                    border: 1px solid grey;
                                    color: grey;

                                    &:hover {
                                        border: 1px solid grey;
                                        color: grey;
                                        background-color: white;
                                    }
                                }

                                input[type="checkbox"] {
                                    position: absolute;
                                    opacity: 0;

                                    &:checked + label {
                                        background-color: map-get($map: $theme-colors, $key: "primary");
                                        color: white;

                                        &.with-suboptions {
                                            height: 10px;
                                            border-radius: 50%;
                                            width: 10px;
                                            // width: 500px;
                                            // height: 500px;
                                            color: map-get($map: $theme-colors, $key: "primary");
                                            overflow: hidden;
                                            // position: fixed;
                                            // top: 0;
                                            // bottom: 0;
                                            // // left: 0;
                                            // // right: 0;

                                            &::before {
                                                content: "";
                                                width: 2px;
                                                height: 10px;
                                                background-color: rgb(255, 255, 255);
                                                position: absolute;
                                                top: 5px;
                                                transform: rotate(-45deg);
                                            }

                                            &::after {
                                                content: "";
                                                width: 2px;
                                                height: 10px;
                                                background-color: rgb(255, 255, 255);
                                                position: absolute;
                                                top: 5px;
                                                transform: rotate(45deg);
                                            }
                                        }
                                    }

                                    &:checked ~ .subOptions {
                                        max-height: 250px;
                                        // height: 20vh;
                                        overflow-y: auto;

                                        // .sub-option-button {
                                        //     display: block;
                                        // }
                                    }
                                }

                                input[type="radio"] {
                                    position: absolute;
                                    opacity: 0;

                                    &:checked + label {
                                        background-color: map-get($map: $theme-colors, $key: "primary");
                                        color: white;

                                        &.with-suboptions {
                                            height: 10px;
                                            border-radius: 50%;
                                            width: 10px;
                                            color: map-get($map: $theme-colors, $key: "primary");
                                        }
                                    }

                                    &:checked ~ .subOptions {
                                        max-height: 250px;
                                        // height: 20vh;
                                        overflow-y: auto;

                                        // .sub-option-button {
                                        //     display: block;
                                        // }
                                    }
                                }

                                input[type="text"] {
                                    // &#other {
                                        height: 100%;
                                        width: 100%;
                                        padding: 10px;
                                        border: 1px solid map-get($map: $theme-colors, $key: "primary");
                                        position: relative;
                                        // cursor: pointer;
                                        border-radius: 5px;
                                        text-align: center;
                                        margin-bottom: 10px;

                                        transition: all ease-in-out 0.2s;

                                        &::placeholder {
                                            color: black;
                                            font-weight: 200;
                                        }

                                        &:hover {
                                            background-color: map-get($map: $theme-colors, $key: "primary");
                                            color: white;

                                            &::placeholder {
                                                color: white;
                                            }
                                        }
                                    }
                                // }

                                input[type="date"] {
                                    height: 100%;
                                    width: 100%;
                                    padding: 10px;
                                    border: 1px solid map-get($map: $theme-colors, $key: "primary");
                                    position: relative;
                                    cursor: pointer;
                                    border-radius: 5px;
                                    text-align: center;

                                    transition: all ease-in-out 0.2s;
                                }

                                .subOptions {
                                    max-height: 0px;
                                    overflow: hidden;
                                    transition: max-height ease-in-out 0.3s;
                                    width: 100%;
                                    height: fit-content;
                                    // margin-bottom: 10px;
                                    position: relative;
                                    // top: 0;
                                    // right: -120%;
                                    // position: relative;
                                    // right: 89%;
                                    // background-color: white;
                                    // z-index: 2;

                                    .sub-option-button {
                                        width: 90%;
                                        float: left;
                                        // position: absolute;
                                        // right: 0;
                                        // display: none;

                                        label {
                                            height: 45px;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .sku-slide-container {
                        #sku-dropdown {
                            background-color: white;
                            padding: 10px;
                            border: 1px solid map-get($map: $theme-colors, $key: "primary");
                            position: relative;
                            cursor: pointer;
                            border-radius: 5px;
                            text-align: center;
                            overflow: hidden;
                            float: left;
                            margin-right: 30px;

                            transition: all ease-in-out 0.3s;

                            &:hover {
                                background-color: map-get($map: $theme-colors, $key: "primary");
                                color: white;
                            }

                            &:focus {
                                outline: none;
                            }

                            option {
                                background-color: white;
                                color: black;

                                &:disabled {
                                    color: grey;
                                }
                            }
                        }

                        .add-btn {
                            background-color: white;
                            padding: 10px;
                            border: 1px solid map-get($map: $theme-colors, $key: "primary");
                            position: relative;
                            cursor: pointer;
                            border-radius: 5px;
                            text-align: center;
                            overflow: hidden;

                            transition: all ease-in-out 0.3s;

                            &:hover {
                                background-color: map-get($map: $theme-colors, $key: "primary");
                                color: white;
                            }
                        }

                        .information-block {
                            list-style: none;
                            padding-left: 10px;
                            margin-top: 25px;

                            .info-container {
                                position: relative;
                                margin-bottom: 8px;
                                width: 30%;
                                height: 50px;
                                border: 1px solid map-get($map: $theme-colors, $key: "primary");
                                margin: 10px;
                                padding: 10px;
                                float: left;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                border-radius: 5px;

                                opacity: 0;
                                top: 10px;

                                transition: all cubic-bezier(0.32, 0.86, 0.71, 1.62) 0.4s;

                                &.show {
                                    opacity: 1;
                                    top: 0;
                                }

                                &#info-1 {
                                    transition-delay: 0s;
                                }

                                &#info-2 {
                                    transition-delay: 0.1s;
                                }

                                &#info-3 {
                                    transition-delay: 0.2s;
                                }

                                &#info-4 {
                                    transition-delay: 0.3s;
                                }

                                &#info-5 {
                                    transition-delay: 0.4s;
                                }

                                // .info {
                                //     display: inline-block;
                                //     position: absolute;
                                //     right: 0;
                                // }
                            }
                        }
                    }

                    .MuiSlider-track {
                        background-color: map-get($map: $theme-colors, $key: "primary");
                        height: 2px;
                    }

                    .MuiSlider-rail {
                        background-color: map-get($map: $theme-colors, $key: "primary");
                    }

                    .MuiSlider-thumb {
                        background-color: map-get($map: $theme-colors, $key: "primary");
                    }

                    .MuiSlider-mark {
                        transform: scale(2.5);
                        background-color: map-get($map: $theme-colors, $key: "primary");
                    }

                    .MuiSlider-markActive {
                        background-color: map-get($map: $theme-colors, $key: "primary");
                    }

                    .MuiSlider-valueLabel {
                        color: map-get($map: $theme-colors, $key: "primary");
                    }

                    // #multi-select {
                    //     @import "~/antd/dist/antd.css";
                    // }
                    .audience-size-container {
                        $height: 135px;
                        $width: 435px;
                        position: absolute;
                        top: 0px;
                        right: 0px;
                        // z-index: ;

                        .info {
                            background-color: white;
                            position: fixed;
                            z-index: 2;
                            box-shadow: 0px 0px 5px -1px #888686;
                            border-radius: 5px;
                            display: flex;
                            text-align: center;
                            justify-content: center;
                            align-items: center;
                            flex-direction: column;
                            padding: 0 10px;
                            // padding-top: 30px;
                            // padding-bottom: 30px;
                            // box-sizing: content-box;

                            // .icon {
                            //     img {
                            //         // margin-bottom: 50px;
                            //         height: 50px;
                            //         width: 50px;
                            //         color: black;
                            //         // margin-bottom: 5px;
                            //     }
                            // }

                            // .heading {
                            //     // font-weight: bolder;
                            //     font-size: 1.2rem;
                            // }

                            // .users {
                            //     font-weight: bolder;
                            //     font-size: 2rem;
                            // }
                        }
                    }
                }
            }

            .nav-buttons {
                // height: 18%;
                display: flex;
                justify-content: space-between;
                padding: 0 50px;
                position: absolute;
                bottom: 50px;
                left: 10px;
                right: 10px;
                font-size: 1.3rem;

                button {
                    background-color: rgb(255, 255, 255);
                    outline: none;
                    border: none;

                    &.next {
                        margin-left: auto;
                    }
                }
            }
        }

        .slick-dots {
            height: 12vh;
            font-family: "Montserrat", sans-serif;
            min-height: 110px;

            ul {
                padding-left: 0;
                display: flex;
                height: 100%;
                color: white;

                .slick-active {
                    color: black;
                    background-color: /* rgb(81, 255, 81) */ white;
                    border: none;
                    // border-top-left-radius: 5px;
                    // border-top-right-radius: 5px;

                    transition: all ease-in-out 0.3s;
                }

                li {
                    width: 25%;
                    margin: 0;
                    height: 100%;
                    transition: all ease-in-out 0.3s;

                    div {
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        transition: all ease-in-out 0.2s;

                        .icon {
                            width: 30px;
                            margin-bottom: 20px;
                        }

                        .nav-title {
                            font-size: 0.85rem;
                        }

                        &.current {
                            background-color: white !important;
                            color: black;
                            // border-top-left-radius: 5px;
                            // border-top-right-radius: 5px;
                        }

                        &.incomplete {
                            background-color: rgb(153, 151, 150);
                            // color: black;
                            // border-top-left-radius: 5px;
                            // border-top-right-radius: 5px;
                        }

                        &.complete {
                            background-color: #77dd77;
                            color: black;
                            // border-top-left-radius: 5px;
                            // border-top-right-radius: 5px;
                        }
                    }
                }
            }
        }

        #button-next,
        #button-sbm {
            height: 50px;
            width: 120px;
            padding: 10px;
            border: 1px solid map-get($map: $theme-colors, $key: "primary");
            position: absolute;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            // transition: all cubic-bezier(.7,-0.75,.31,1.7) 0.5s;
            transition: background-color ease-in-out 0.3s, color ease-in-out 0.3s,
                all cubic-bezier(0.7, -0.75, 0.31, 1.7) 0.5s;
            background: none;
            bottom: 30px;
            right: 20px;

            &:hover {
                background-color: map-get($map: $theme-colors, $key: "primary");
                color: white;

                .spinner-border-sm.spinner-border.text-primary {
                    color: white
                }
            }

            &.hidden {
                opacity: 0;
                bottom: -80px;
            }
        }
    }

    #button-sbm[disabled], #button-sbm:disabled {
        border-color: grey;
        color: grey;

        &:hover {
            background-color: white;
            color: grey;
        }
    }

    // .row {
    //     height: 100%;

    //     .nav-pills {
    //         height: 100%;
    //         background-color: #e0e0e0;
    //         border-top-left-radius: 5px;
    //         border-bottom-left-radius: 5px;

    //         .nav-item {
    //             height: 25%;

    //             .nav-link {
    //                 color: black;
    //                 display: flex;
    //                 align-items: center;
    //                 justify-content: center;
    //                 height: 100%;
    //                 border: none;

    //                 &:hover {
    //                     border: none;
    //                 }
    //             }

    //             .active {
    //                 color: black;
    //                 background-color: white;
    //                 border: none;

    //                 transition: all ease-in-out 0.3s;
    //             }
    //         }
    //     }

    // }

    // .nav-tabs {
    //     height: 10%;
    //     background-color: #e0e0e0;
    //     border-top-left-radius: 5px;
    //     border-top-right-radius: 5px;

    //     .nav-item {
    //         .nav-link {
    //             color: black;
    //             display: flex;
    //             align-items: center;
    //             justify-content: center;
    //             height: 100%;
    //             border: none;

    //             &:hover {
    //                 border: none;
    //             }
    //         }

    //         .active {
    //             color: black;
    //             background-color: white;
    //             border: none;

    //             transition: all ease-in-out 0.3s;
    //         }
    //     }
    // }

    // .tab-content {
    //     height: 80%;

    //     .tab-pane {
    //         height: 100%;

    //         .question-slider {
    //             height: 100%;

    //             .slick-slider {
    //                 display: flex;
    //                 justify-content: center;
    //                 align-items: center;
    //                 height: 100%;
    //             }
    //         }
    //     }
    // }
}

.modal-footer {
    button {
        padding: 10px;
        position: relative;
        cursor: pointer;
        border-radius: 5px;
        text-align: center;
        overflow: hidden;

        transition: all ease-in-out 0.3s;

        &#save-btn {
            border: 1px solid map-get($map: $theme-colors, $key: "primary");
            background-color: map-get($map: $theme-colors, $key: "primary");
            color: white;

            &:hover {
                background-color: map-get($map: $theme-colors, $key: "primary");
                color: white;
            }
        }

        &#cancel-btn {
            border: 1px solid map-get($map: $theme-colors, $key: "danger");
            background-color: white;

            &:hover {
                background-color: map-get($map: $theme-colors, $key: "danger");
                color: white;
            }
        }
    }
}

.audience-select-button {
    height: 35px;
    // height: 100%;
    width: 100%;
    // padding: 10px;
    border: 1px solid map-get($map: $theme-colors, $key: "primary");
    // position: relative;
    // cursor: pointer;
    border-radius: 5px !important;
    background-color: white;
    margin-top: 20px !important;
    // text-align: center;
    // overflow: hidden;
    // display: flex;
    // align-items: center;
    // justify-content: center;

    transition: all ease-in-out 0.3s;

    &:hover {
        background-color: map-get($map: $theme-colors, $key: "primary");
        color: white;
    }

    &.selected {
        background-color: map-get($map: $theme-colors, $key: "primary");
        color: white;
    }
}

.targeting-component {
    button {
        height: 35px;
        // height: 100%;
        width: 100%;
        // padding: 10px;
        border: 1px solid map-get($map: $theme-colors, $key: "primary");
        // position: relative;
        // cursor: pointer;
        border-radius: 5px !important;
        background-color: white;
        margin-top: 20px !important;
        // text-align: center;
        // overflow: hidden;
        // display: flex;
        // align-items: center;
        // justify-content: center;

        transition: all ease-in-out 0.3s;

        &:hover {
            background-color: map-get($map: $theme-colors, $key: "primary");
            color: white;
        }

        &.selected {
            background-color: map-get($map: $theme-colors, $key: "primary");
            color: white;
        }
    }

    .red-star {
        margin-left: 5px;
        font-size: 1rem;
        position: relative;
        top: -5px;
        color: red;
    }

    // .ant-select-selector {

    // .main-content {
    //     font-weight: 400;
    // }

    // .info-content {
    //     font-size: 0.5rem;
    // }
    // }
}

.age-question-component {
    .age-dropdown {
        width: 150px;
        background-color: white;
        padding: 10px;
        border: 1px solid map-get($map: $theme-colors, $key: "primary");
        position: relative;
        cursor: pointer;
        border-radius: 5px;
        text-align: center;
        overflow: hidden;
        float: left;

        transition: all ease-in-out 0.3s;

        &:hover {
            background-color: map-get($map: $theme-colors, $key: "primary");
            color: white;
        }

        &:focus {
            outline: none;
        }

        option {
            background-color: white;
            color: black;

            &:disabled {
                color: grey;
            }
        }
    }
    .hyphen {
        margin: auto 15px;
        display: block;
        float: left;
        // height:px;
        position: relative;
        top: 10px;
    }
}

// .semantic-dropdown {
//     @import '../../../node_modules/semantic-ui-css/semantic.min.css';
// }
#campaign-submit {
    position: relative;
    height: calc(100vh - 200px);
    width: 50vw;
    margin: auto;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 3px 13px 1px hsla(0, 0%, 58%, 0.32);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    // .heading {
    //     font-size: 8rem;
    //     color: #ea4a5b;
    //     font-family: Ubuntu;
    // }

    .sub-heading {
        font-size: 2.5rem;
        color: rgb(150, 150, 150);
        font-family: Open sans;
    }

    .buttons {
        button {
            background: none;
            padding: 2em 3em;
            border-radius: 5px;
            border: 1px solid map-get($theme-colors, "primary");
            cursor: pointer;
            position: relative;
            // z-index: 3;
            // width: 170px;
            // height: 70px;
            font-size: 1.1rem;
            font-family: Open sans;
            font-weight: 700;
            color: black;
            transition: all ease-in-out 0.3s;
            margin-top: 50px;

            &:hover {
                // font-weight: bolder;
                background-color: map-get($theme-colors, "primary");
                color: white;
            }
        }
    }
}
