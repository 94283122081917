.sidebar {
  width: 17%;
  height: 90%;
  background-color: white;
  border-right: 1px solid rgba(0, 0, 0, 0.27);
  // margin-top: 30px;
  position: absolute;
  left: -17%;
  opacity: 0;

  transition: all ease-in-out 0.3s;

  &.show {
    left: 0;
    opacity: 1;
    // position: relative;
    // z-index: 20000;
  }

  

  .scroll-nav {
    height: 90%;
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    // align-items: center;
    flex-direction: column;

    .flex-column {
      padding: 0 10px;
      
      
      .ad-card {
        color: black;
        font-size: 1.3rem;
        margin: 10px 0;
        width: 85%;
        margin-bottom: 50px;
      }
    }

  }

  .scroll-nav::-webkit-scrollbar {
    width: 3px !important;
  }

  .scroll-nav::-webkit-scrollbar-thumb {
    background-color: rgb(168, 168, 168);
  }

  .nav-footer {
    height: 5%;
    width: 100%;
    background-color: white;
    box-shadow: 1px 1px 1px #ccc;
    position: absolute;
    bottom: 0;
    z-index: 2;
  }
}

.section {
  background-color: white;
  box-shadow: 1px 1px 1px #ccc;
  height: 100%;

  .section-heading {
    z-index: 2;
    width: 100%;
    height: 5vh;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding-left: 14px;
    background-color: white;
    border-bottom: 1px solid #cccac9;

    h1 {
      font-family: 'Raleway', sans-serif;
      font-size: 1.3rem;
      color: rgb(97, 96, 96);
      display: flex;
      align-items: center;
      margin-bottom: 0;

      .graphic {
        margin-right: 20px;
        font-size: 1.8rem;
      }
    }

    .icon {
      margin-left: auto;
      margin-right: 15px;
      position: relative;
      top: -2px;
      cursor: pointer;
    }
  }
}

.shadow-giving-class {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}
