.comments {
    // background-color: white;
    height: 94%;
    width: 25%;
    position: absolute;
    right: -25%;
    // opacity: 0;
    top: -5px;
    transition: all ease-in-out 0.3s;

    .show {
        opacity: 1;
        right: 102%;
        // background-color: black;
    }

    // .ui.comments {
        // margin-top: 30px !important;
        .section {
            height: 65%;
        }

        .section-body {
            // padding-left: 20px;
            height: 100%;

            // .comment {
            //     // width: 90%;

            //     // &:nth-child(1) {
            //     //     margin-top: 20px;
            //     // }
            // }

            // &:last-child {
            //     margin-bottom: 20px;
            // }
        }

        // &.comment-vala {
        //     // padding: 20px;
        // }
    // }
}

#comment-list {
    height: 100%;
    padding: 20px;
    // background-color: black;
    overflow: auto;

    .placeholder-comments {
        color: grey;
    }
}

#input-comment {
    margin-top: 10px;
    background: none;
    height: fit-content;

    .section-body {
        height: fit-content;
        padding: 20px 20px 10px 20px ;
        background-color: white;

        .comment-form {
            height: 100%;
        }
    }
}

.ui .comments {
    max-height: none;
    width: 100%;
    position: relative;
    // z-index: -45;
}
