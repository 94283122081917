.chatbot-integration {
    background: white;
    border-radius: 5px;
    margin-top: 2em;
    padding: 1em 1em;
}

.chatbot-integration__img-container {
    margin: auto;
    border: 1px solid black;
    display: flex;
    flex-direction: column;

    .chatbot-integration__img {
        margin: auto;
        width: 80%;
    }
}

.chatbot-integration__form {
    margin: auto;
    width: 100%;
    background: #f7f7f7;
    border: 1px solid black;
    display: flex;
    flex-direction: column;
    padding: 2em 2em;
}