.carousal-comment-container {
    background-color: #f2f2f2;
    padding-top: 10px;
    height: 100vh;
    width: 100%;
    display: flex;
    // justify-content: center;
    position: relative;

    .show-button {
        height: 30px;
        width: 50px;
        background-color: white;
        position: absolute;
        top: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.2rem;
        box-shadow: 1px 1px 3px -1px #676262;
        cursor: pointer;
    }
    
    
}

.sidebar-col::-webkit-scrollbar {
    width: 3px !important;
}

.sidebar-col::-webkit-scrollbar-thumb {
    background-color: rgb(168, 168, 168);
}

#sidebar-column {
    max-width: 100%;
}
