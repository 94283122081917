#tasks-container {
    padding: 20px;

    ul {
        margin: 0px -15px 4px;
        font-size: 1.3em;

        .mybadge {
            font-size: 0.6em;
            border-radius: .25rem;
            padding: .25em .45em;
            margin: 3px 5px 3px 15px;
            color: #fff
        }

        .notif-b {
            background-color: #e04040;
        }

        .task-b {
          background-color: #f0ad4e;
        }
    }

    .dismiss-container {
        width: 77%;
        text-align: right;
        padding-top: 5px;
    }

    .header-bar {
        background-color: #ffffff;
        border: 1px solid #cfcfcf;
        // border-radius: 3px;
        padding-left: 4px;
        // border-left: 5px solid transparent;

        .col {
            text-align: center;
        }

        .main-heading {
            padding: 10px 25px;
            color: #000000;
            font-size: 1.3em;
            font-weight: 500;
            margin-bottom: 0px;
        }

        .categ-heading {
            flex-grow: 10;
            max-width: 300px;
        }

        .task-heading {
            border-left: 1px solid #cfcfcf;
            flex-grow: 5.2;
            max-width: 200px;
        }

        .custom-heading {
            border-left: 1px solid #cfcfcf;
            flex-grow: 5;
            max-width: 180px;
        }

        .pr-heading {
            border-left: 1px solid #cfcfcf;
            flex-grow: 2.1;
            max-width: 120px;
        }
    }
    
    .categ-wrapper {
        margin-top: 10px;
        margin-bottom: 20px;
        background-color: #f7f7f7;
        align-items: center;
        outline: 1px solid #cfcfcf;
        outline-offset: -1px;

        .category-column {
            margin-bottom: 0px;
            flex-grow: 5;
            max-width: 300px;
            border-radius: 3px 0px 0px 3px;
            text-align: center;

            .categ-name {
                font-weight: bold;
                font-size: 1.4em;
            }
        }
    
        .data-column {
            margin-bottom: 0px;
            outline: 1px solid #c2c1c1;
            outline-offset: -1px;
            flex-grow: 14.34;

            .data-wrapper1 {
                border-radius: 0px 3px 3px 0px;
                background-color: #f7f7f7;
                border-bottom: 1px solid #c2c1c1;
                align-items: center;
                outline: 1px solid #cfcfcf;
                outline-offset: -1px;

                .task-name {
                    margin-bottom: 0px;
                    padding: 10px 10px 10px 20px;
                    flex-grow: 4.6;
                    max-width: 200px;
                    font-weight: 500;
                }

                .task-data {
                    margin-bottom: 0px;
                    padding: 10px;
                    flex-grow: 4.6;
                    max-width: 180px;
                    font-weight: 500;
                    border-left: 1px solid #c2c1c1;
                    text-align: center;

                    .date-stamp {
                        padding: 2px 10px;
                        border-radius: 4px;
                        border: 1px solid #91d5ff;
                        background-color: #e6f7ff;
                        color: #1890ff;
                        font-size: 0.9em;
                    }
                }

                .task-pr {
                    margin-bottom: 0px;
                    padding: 5px;
                    border-left: 1px solid #c2c1c1;
                    text-align: center;
                    flex-grow: 2.9;
                    max-width: 120px;

                    .pr-img {
                        margin: 0px;
                        padding: 0px;
                        height: 30px;
                        width: 30px;
                    }

                    .pr-text {
                        margin-left: 5px;
                        font-size: 0.76em;
                    }

                    .txt-red {
                        color: #e31801;
                    }

                    .txt-ylw {
                        color: #fbbb2b;
                    }

                    .txt-grn {
                        color: #b1d43a;
                    }
                }
            }

            .data-wrapper2 {
                padding: 20px 10px;
                background-color: #ffffff;
            }

            .desc-column {
                padding: 0px 10px;
                flex-grow: 3;
                min-width: 500px;
            }

            .btn-group {
                margin: 0px;
                flex-grow: 1;
                justify-content: flex-end;

                .completed-badge {
                    height: 60%;
                    padding: 2px 10px;
                    border-radius: 4px;
                    border: 1px solid #b7eb8f;
                    background-color: #f6ffed;
                    color: #52c41a;
                }
            }

            .spcl-wrapper {
                padding: 20px 10px;
            }

            .when-show {
                background-color: #f7f7f7;
            }

            .when-hide {
                background-color: #ffffff;
            }

            .spcl {
                margin: 0px;
                outline: 1px solid #c2c1c1;
                outline-offset: -2px;
            }
        }

        .txt-green {
            color: #41a741;
        }
        .txt-sea {
            color: #46b17f;
        }
        .txt-brown {
            color: #776145;
        }
        .txt-orange {
            color: #d6a502;
        }
        .txt-pink {
            color: #b148b1;
        }
        .txt-red {
            color: #c45252;
        }
        .txt-blue {
            color: #4853b1;
        }
    }

    .bdr-green {
        border-left: 5px solid #77dd77;
    }
    .bdr-sea {
        border-left: 5px solid #78daac;
    }
    .bdr-brown {
        border-left: 5px solid #a58d6e;
    }
    .bdr-orange {
        border-left: 5px solid #fdda68;
    }
    .bdr-pink {
        border-left: 5px solid #ea7ff8;
    }
    .bdr-red {
        border-left: 5px solid #f87f7f;
    }
    .bdr-blue {
        border-left: 5px solid #6495ff;
    }
}