@import "variables";

// libraries
@import "~bootstrap/scss/bootstrap.scss";
@import "~react-infinite-calendar/styles";

// base
@import "base";
@import "overrides";
@import "extends";
@import "animations";
@import "utils/utils";

// components
@import "components/auth";
@import "components/chatbot-integration";
@import "components/custom-button";
@import "components/create-a-campaign";
@import "components/form-input";
@import "components/header";
@import "components/sidebar";
@import "components/content";
@import "components/page";
@import "components/widget";
@import "components/search-input";
@import "components/sign-in";
@import "components/loading-page";
@import "components/billing";
@import "components/tasks";

// themes
@import "themes/colors";

// demos (only for demo pages)
@import "demos/demos";
