// .inventory-scheduler-form {
//     // height: 50vh;
// }

.custom-button-container {
    display: flex;
}

option {
    text-transform: uppercase;
}