.mapContainer {
  max-height: 7 00px;
  height: 80vh;
}

.mapboxgl-canvas {
  height: 100%;
  width: 100%;
}

.mapboxgl-canvas-container {
  height: 80vh;
}

.map-overlay {
  font: 12px/20px "Helvetica Neue", Arial, Helvetica, sans-serif;
  position: fixed;
  width: 200px;
  bottom: 80px;
  padding: 10px;
  z-index: 10;
}
.map-overlay .map-overlay-inner {
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  padding: 10px;
  margin-bottom: 10px;
}
