.charts-container {
    display: flex;
    flex-wrap: wrap;

    .chart-container {
        box-shadow: 0px 0px 5px -2px #7b7575;
        border-radius: 7px;
        margin: 10px;
        height: 100%;
        
        .chart-nav {
            position: relative;
            padding: 10px 20px;
            background-color: white;
            border-top-left-radius: 7px;
            border-top-right-radius: 7px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .chart-handle {
                font-size: 2rem;
                display: flex;
                // float: left;
                cursor: grab;

                &:active {
                    cursor: grabbing;
                }
            }

            .heading {
                display: inline-block;
                text-transform: capitalize;
            }

            .chart-change .icon {
                font-size: 1.7rem;
                transform: rotate(90deg);
                cursor: pointer;
            }

            .info-icon {
                font-size: 1.25rem;
                cursor: pointer;
                display: inline-block;
                margin-left: 13px;
                margin-top: -5px;
            }

            .data-icon {
                font-size: 1.5rem;
                cursor: pointer;
            }

            .icon-wrapper {
                padding: 4px;
                display: inline-block;
            }

            .chart-options {
                position: absolute;
                top: 50px;
                right: 10px;
            }

            .data-options {
                position: absolute;
                top: 50px;
                right: 30px;
            }

            .options {
                background-color: white;
                box-shadow: 0px 0px 5px -2px #7b7575;
                border-radius: 5px;               
                display: none;

                .divider {
                    width: 100%;
                    height: 1px;
                    background-color: rgb(192, 191, 191);
                }

                .option-description {
                    color: grey;
                    border-bottom: 1px solid rgb(192, 191, 191);
                    cursor: default;
                    &:hover {
                        background-color: white;
                    }
                }

                li {
                    list-style: none;
                    padding: 1em 1.5em;
                    cursor: pointer;
                    font-size: 0.9rem;

                    &:hover {
                        background-color: rgb(245, 245, 245);
                    }
                }

                &.show {
                    display: block;
                }
            }
        }

        .chart {
            padding: 30px;
        }
    }
}

.widget-stats {
    margin-top: 10px;
}