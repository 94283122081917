// colors

.bg-gradient-theme {
  background: linear-gradient(to bottom, theme-color("primary"), theme-color("secondary"));
}
.bg-gradient-theme-top {
  background: linear-gradient(to top, theme-color("primary"), theme-color("secondary"));
}
.bg-gradient-theme-left {
  background: linear-gradient(to left, theme-color("primary"), theme-color("secondary"));
}
.bg-gradient-theme-right {
  background: linear-gradient(to right, theme-color("primary"), theme-color("secondary"));
}

// typography
// This should be placed somewhere else, this is not the right place.
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
}
