.sign-in-and-sign-up {
    // background: rgb(234,74,91);
    // background: linear-gradient(90deg, rgba(234,74,91,0.75) 0%, rgba(2,29,222,0.75) 100%); 
    background: rgb(219, 233, 247);
    background-size: cover;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}